import "./features.css";
import ownerIcon from "../../assets/owner-icon.svg";
import renterIcon from "../../assets/renter-icon.svg";
import brokerIcon from "../../assets/broker-icon.svg";
import Grid from "@mui/material/Grid";

const Features = () => {
  return (
    <div className="features-root">
      <div className="features-box1">
        <div className="features-box3">
          <div className="features-text1">مزايا الانطلاق مع قسطار</div>
          <div className="features-text2">
            قسطار لديها عدد من الامتيازات تناسب إحتياجاتك
          </div>
        </div>
      </div>

      <div className="features-box2">
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={12} sm={4}>
            <div className="why-box4 ">
              <div className="why-box5">
                <div className="why-text1">مكتب \ شركة إدارة الأملاك </div>
                <div className="features-icon">
                  <img src={brokerIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="why-text2 f-normal">
                    توفير جهد المبذول في متابعة عمليات التحصيل الشهرية
                  </div>
                  <div className="why-text2 f-normal">
                    خيار الدفع الشهري المضمون،
                  </div>
                  <div className="why-text2 f-normal">
                    .منصة لادارة عملية التأجير وتوثيق العقود بالربط مع منصة
                    ايجار
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="features-box4 ">
              <div className="why-box5">
                <div className="features-text3">كمستأجر لعقار جديد</div>
                <div className="features-icon">
                  <img src={renterIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="features-text3 f-normal">
                    ،التواصل عبر منصة واحدة
                  </div>
                  <div className="features-text3 f-normal">
                    ،أقساط شهرية ميسرة
                  </div>
                  <div className="features-text3 f-normal">.منصة معتمدة </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="why-box4 ">
              <div className="why-box5">
                <div className="why-text1">كمالك لعقار</div>
                <div className="features-icon">
                  <img src={ownerIcon} alt="owner-i" />
                </div>
              </div>
              <div className="why-box6">
                <div className="why-box7">
                  <div className="why-text2 f-normal">
                    ضمان التدفق المالي الشهري
                  </div>
                  <div className="why-text2 f-normal">
                    الحصول على ميزة تسويقية من خلال توفير خيار دفع الايجار بشكل
                    شهري
                  </div>
                  <div className="why-text2 f-normal">
                    .عائد مالي أعلى عند التحول الى التأجير بشكل شهري{" "}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Features;
