import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import { motion, AnimateSharedLayout } from "framer-motion";
import { useState } from "react";
import frame1 from "../../assets/Frame2.svg";
import frame2 from "../../assets/owner-frame.svg";
import frame3 from "../../assets/broker-frame.svg";
import arrow from "../../assets/arrow.svg";
import check from "../../assets/check-solid.svg";
import Ellipse2 from "../../assets/Ellipse2.svg";
import { useNavigate } from "react-router-dom";

const ForWhoSection = ({ scroll }) => {
  const [expand, setExpand] = useState("frame1");
  const navigate = useNavigate();

  const handleClickSignup = () => {
    // navigate("/login");
    // console.log(signupRef);
    scroll();
  };

  const handleExpand = (frame) => {
    setExpand(frame);
  };
  const unfocused = {
    // display: "flex",
    height: "4.375rem",
    padding: "0.5rem 1rem",
    justifyContent: "flex-end",
    // alignItems: "center",
    gap: "0.5rem",
    alignSelf: "stretch",

    borderRadius: "0.5rem",
    border: "1px solid rgba(67, 122, 179, 0.08)",
    background: "#FFF",
    boxShadow: "0px 16px 32px 0px rgba(0, 74, 152, 0.09)",
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  };

  const UnfocusedDiv = ({ expanded, text }) => {
    return (
      <motion.div
        // layoutId={text}
        className="ForWho-acc-unfocused"
        onClick={() => handleExpand(expanded)}
      >
        <div className="ForWho-text-3">{text}</div>
      </motion.div>
    );
  };

  const FocusedDiv = ({ text, img, expanded }) => {
    return (
      <motion.div
        className="ForWho-acc-focused"
        animate={expanded === expand ? "open" : "closed"}
        variants={variants}
      >
        <div className="ForWho-text-4">{text}</div>
        <img src={img} alt="frame" className="frame-img" />
      </motion.div>
    );
  };

  const FrameDiv1 = () => {
    return (
      <>
        {expand === "frame1" ? (
          <FocusedDiv img={frame1} text="المستأجر" expanded="frame1" />
        ) : (
          <UnfocusedDiv expanded="frame1" text="المستأجر" />
        )}
      </>
    );
  };
  const FrameDiv2 = () => {
    return (
      <>
        {expand === "frame2" ? (
          <FocusedDiv img={frame2} text="المالك" expanded="frame2" />
        ) : (
          <UnfocusedDiv expanded="frame2" text="المالك" />
        )}
      </>
    );
  };
  const FrameDiv3 = () => {
    return (
      <>
        {expand === "frame3" ? (
          <FocusedDiv
            img={frame3}
            text="مكتب \ شركة إدارة الأملاك"
            expanded="frame3"
          />
        ) : (
          <UnfocusedDiv expanded="frame3" text="مكتب \ شركة إدارة الأملاك" />
        )}
      </>
    );
  };

  const RenterFeatures = () => {
    if (expand !== "frame1") return null;
    return (
      <div className="ForWho-sec2-tab">
        <div className="ForWho-text-5-con">
          <div className="ForWho-text-5">المستأجر</div>
        </div>

        <div className="frame22">
          <div className="frame13">
            <div className="ForWho-text-8">المميزات</div>
          </div>
          <div className="frame14">
            <div className="frame15">
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  دفعات شهرية ميسرة يسهل عملية إدارة التدفقات المالية الشهرية
                  سواء شركة او فرد.
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  توحيد مواعيد الدفع لجميع فروعك و التواصل من خلال منصة واحدة
                  فقط
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  خطة دفعات لضبط ميزانيتك لضمان نجاح توسع مشروعك التجاري وتجنب
                  تراكم الدفعات المُستحقة.
                </div>
              </div>
            </div>
          </div>
          <div className="ForWho-btn1" onClick={handleClickSignup}>
            <div className="ForWho-text-10">سجل الآن</div>
          </div>
        </div>
      </div>
    );
  };
  const OwnerFeatures = () => {
    if (expand !== "frame2") return null;
    return (
      <div className="ForWho-sec2-tab">
        <div className="ForWho-text-5-con">
          <div className="ForWho-text-5">المالك</div>
        </div>

        <div className="frame22">
          <div className="frame13">
            <div className="ForWho-text-8">المميزات</div>
          </div>
          <div className="frame14">
            <div className="frame15">
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  نمو دائم لعقاراتك التجارية عبر تحصيل مبالغ التأجيرالشهري
                  بإنتظام.
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  ضمان دفعات الإيجار الشهري في حالة تعثر المستأجر.
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  ارفع عوائدك الايجارية من خلال توفير خيار الدفع الشهري
                  للمستأجرين.
                </div>
              </div>
            </div>
          </div>
          <div className="ForWho-btn1" onClick={handleClickSignup}>
            <div className="ForWho-text-10">سجل الآن</div>
          </div>
        </div>
      </div>
    );
  };
  const BrokerFeatures = () => {
    if (expand !== "frame3") return null;
    return (
      <div className="ForWho-sec2-tab">
        <div className="ForWho-text-5-con">
          <div className="ForWho-text-5">مكتب \ شركة إدارة الأملاك</div>
        </div>

        <div className="frame22">
          <div className="frame13">
            <div className="ForWho-text-8">المميزات</div>
          </div>
          <div className="frame14">
            <div className="frame15">
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  وفّر جهد عمليات تحصيل الإيجارات الشهرية والاجراءات القانونية.
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  أتم صفقاتك العقارية بنجاح من خلال توفير خيار دفع الايجار بشكل
                  شهري.
                </div>
              </div>
              <div className="frame16">
                <div className="icon-frame">
                  <img src={check} alt="check" />
                </div>
                <div className="ForWho-text-9 f-normal">
                  احصل على ميزة تسويقية من خلال ضمان الدفع في حالة تعثر
                  المستأجر.
                </div>
              </div>
            </div>
          </div>
          <div className="ForWho-btn1" onClick={handleClickSignup}>
            <div className="ForWho-text-10">سجل الآن</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="ForWho-root">
      <div className="ForWho-text-root">
        <div className="ForWho-text-1">من يستخدم قسطار</div>
        <div className="ForWho-text-2">
          : إستخدام منصة قسطار لها ثلاث أطراف وهي
        </div>
      </div>
      <div className="ForWho-sec2">
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          // alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item xs={12} md={5}>
            <div className="ForWho-sec2-btns">
              <FrameDiv1 />
              <FrameDiv2 />
              <FrameDiv3 />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className="ForWho-sec2-btns">
              <RenterFeatures />
              <OwnerFeatures />
              <BrokerFeatures />
            </div>
          </Grid>
        </Grid>

        {/* features tap */}
      </div>
      <img src={Ellipse2} alt="Ellipse" className="ForWho-shape" />
    </div>
  );
};

export default ForWhoSection;
